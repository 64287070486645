<template>
    <div class="blind">
        <h5header :name="$t('blind.desc47')" :right="false" />
        <div class="blind-con">
            <div class="blind-title flex">
                <div>{{ $t('blind.desc47') }}</div>
                <img src="../../public/images/new/closeicon3.png" alt="" @click="$router.go(-1)" />
            </div>
            <div class="blind-count flex">
                <div>{{ $t('blind.desc48') }}： <span>{{ $t('blind.desc59', { n1: info.count || 0}) }}</span> </div>
                <div>{{ $t('blind.desc49') }}： <span>{{ $t('blind.desc60', { n1: info.counts || 0}) }}</span></div>
                <div>{{ $t('blind.desc50') }}： <span>{{info.totalAmount || 0}} DU</span></div>
            </div>
            <div class="blind-table">
                <div class="table-thead flex">
                    <div>{{ $t('blind.desc51') }}</div>
                    <div>{{ $t('blind.desc52') }}</div>
                    <div>{{ $t('blind.desc53') }}</div>
                    <div>{{ $t('blind.desc54') }}</div>
                    <div>{{ $t('blind.desc55') }}</div>
                    <div>{{ $t('blind.desc56') }}</div>
                    <div>{{ $t('blind.desc57') }}</div>
                </div>
                <div class="table-tbody">
                    <div class="tbody-info flex" v-for="item in list" :key="item.id">
                        <div>{{ item.datetime }}</div>
                        <div>{{ item.blindName }}</div>
                        <div>{{ item.price }} {{ item.prizeName }}</div>
                        <div>{{ $t('blind.desc61') }}</div>
                        <div>{{ item.datetime }}</div>
                        <div>{{ item.accountTypeName }}</div>
                        <div @click="$router.push(`/record/detail?id=${item.coinId}&act=${item.accountType}`)">{{ $t('blind.desc62') }}</div>
                    </div>
                </div>
                <div class="no-data" v-if="showNull">
                        <img src="../../public/images/no-data.png" alt="" />
                        <span>{{ $t("record.desc13") }}</span>
                    </div>
                <div class="planet-page">
                    <el-pagination layout="prev, pager, next" :pager-count="5" :page-size="10" :current-page="page"
                        :total="totals" :hide-on-single-page="true" @current-change="handlerCurrent">
                    </el-pagination>
                </div>
            </div>

        </div>
        <!-- h5 -->
        <div class="h5con">
            <div class="blind-count flex">
                <div class="count-info">
                    <div>{{ $t('blind.desc59', { n1: info.count || 0}) }}</div>
                    <p>{{ $t('blind.desc48') }}</p>
                </div>
                <div class="count-info">
                    <div>{{ $t('blind.desc60', { n1: info.counts || 0}) }}</div>
                    <p>{{ $t('blind.desc49') }}</p>
                </div>
                <div class="count-info">
                    <div>{{info.totalAmount || 0}} DU</div>
                    <p>{{ $t('blind.desc50') }}</p>
                </div>

            </div>
            <div class="h5con-list">
                <div class="list-info"  v-for="item in list" :key="item.id">
                    <div class="flex">
                        <div>{{ $t('blind.desc51') }}</div>
                        <p>{{ item.datetime  }}</p>
                    </div>
                    <div class="flex">
                        <div>{{ $t('blind.desc52') }}</div>
                        <p>{{item.blindName  }}</p>
                    </div>
                    <div class="flex">
                        <div>{{ $t('blind.desc53') }}</div>
                        <p>{{ item.price }} {{ item.prizeName }}</p>
                    </div>
                    <div class="flex">
                        <div>{{ $t('blind.desc54') }}</div>
                        <p>{{ $t('blind.desc61') }}</p>
                    </div>
                    <div class="flex">
                        <div>{{ $t('blind.desc55') }}</div>
                        <p>{{ item.datetime }}</p>
                    </div>
                    <div class="flex">
                        <div>{{ $t('blind.desc56') }}</div>
                        <p>{{ item.accountTypeName }}</p>
                    </div>
                    <div class="btn flexcenter" @click="$router.push(`/record/detail?id=${item.coinId}&act=${item.accountType}`)">
                        {{ $t('notice.desc2') }}
                    </div>
                </div>
                <div class="no-data" v-if="showNull">
                        <img src="../../public/images/no-data.png" alt="" />
                        <span>{{ $t("record.desc13") }}</span>
                    </div>
                <div class="planet-page">
                    <el-pagination layout="prev, pager, next" :pager-count="5" :page-size="10" :current-page="page"
                        :total="totals" :hide-on-single-page="true" @current-change="handlerCurrent">
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import h5header from "@/componets/h5header.vue";
export default {
    components: {
        h5header
    },
    data() {
        return {
            list: [],
            page: 1,
            totals: 0,
            info: {},
            showNull:false
        }
    },
    mounted() {
        this.$post(this.URL.blind.blindBoxTotal, {}).then(res => {
            if (res.code == 0) {
                this.info = res.data;
            }
        })
        this.getlist()
    },
    methods: {
        handlerCurrent(val) {
            this.page = val;
            this.list = [];
            this.getlist();
        },
        getlist() {
            this.$post(this.URL.blind.record, {
                page: 1,
                pageSize: 10
            }).then(res => {
                if (res.code == 0) {
                    this.list = res.data.list;
                    this.totals = res.data.page.count;
                    if(this.totals==0){
                        this.showNull=true;
                    }
                    this.list.forEach(e => {
                        let time = this.common.dateBJtoLocal(e.createTime), time1 = time.getTime(), time2 = 4 * 60 * 60 * 1000;
                        e.datetime = this.common.formatDate(time1 + time2);
                        if (e.accountType == 1) {
                            e.accountTypeName = this.$t('new.desc7')
                        } else if (e.accountType == 2) {
                            e.accountTypeName = this.$t('new.desc8')
                        } else {
                            e.accountTypeName = this.$t('new.desc9')
                        }
                    })
                }
            })
        }
    }
}
</script>

<style lang="less" scoped>
.blind {
    width: 100%;
    padding: 136px 0 80px;
    background: url("../../public/images/new/landbg.png") center no-repeat;
    background-size: 100% 100%;

    .blind-con {
        width: 1200px;
        padding: 40px;
        margin: 0 auto;
        border-radius: 12px;
        background: #1C1C1C;

        .blind-title {
            font-size: 20px;
            color: #FFFFFF;
            justify-content: space-between;
            line-height: 24px;

            img {
                width: 16px;
                height: 16px;
                margin-top: 4px;
                cursor: pointer;
            }
        }

        .blind-count {
            margin: 20px 0;
            font-size: 16px;
            color: #fff;
            line-height: 16px;

            div {
                margin-right: 17px;

                &:last-child {
                    margin-right: 0;
                }
            }

            span {
                color: #C9FA5B;
            }
        }

        .table-thead {
            padding: 20px;
            background: #010203;
            font-size: 14px;
            color: rgba(255, 255, 255, 0.6);

            div {
                flex: 1;

                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(6) {
                    flex: 0 0 130px;
                }

                &:last-child {
                    flex: 0 0 100px;
                    text-align: right;
                }
            }
        }

        .tbody-info {
            padding: 30px 20px;
            border-bottom: 1px solid rgba(60, 60, 60, 1);

            div {
                flex: 1;
                font-size: 16px;
                color: rgba(255, 255, 255, 0.6);

                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(6) {
                    flex: 0 0 130px;
                }

                &:last-child {
                    flex: 0 0 100px;
                    text-align: right;
                    color: #C9FA5B;
                    cursor: pointer;
                }
            }
        }
    }

    .h5con {
        display: none;
    }
}
.no-data {
    width: 100%;
    padding: 130px 0;

    img {
        display: block;
        width: 120px;
        height: 120px;
        margin: 0 auto 16px;
    }

    span {
        display: block;
        margin: 0 auto;
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #a1a1a1;
    }
}
.planet-page {
    margin-top: 40px;
    padding: 0;

    ::v-deep .el-pagination {
        button {
            background-color: rgba(255, 255, 255, 0);
            color: #666666;
            font-size: 14px;
        }

        .el-pager {
            li {
                background: rgba(255, 255, 255, 0);
                font-size: 14px;
                color: #666666;
            }

            .is-active {
                background: #c9fa5b !important;
                border-radius: 4px !important;
                color: #000000 !important;
            }
        }
    }
}

@media (max-width: 1200px) {
    .blind {
        padding: 88px 16px 60px;

        .blind-con {
            display: none;

        }

        .h5con {
            display: block;

            .blind-count {
                flex-wrap: wrap;
                padding: 20px 16px;
                border-radius: 8px;
                background: #181818;

                .count-info {
                    flex: 1;
                    font-size: 12px;
                    color: #A8A8A8;

                    &:last-child {

                        div {
                            color: #C9FA5B;
                        }
                    }

                    div {
                        margin-bottom: 10px;
                        font-size: 14px;
                        color: #FFFFFF;
                    }
                }
            }
            .h5con-list{
                margin-top: 20px;
                .list-info{
                    margin-top: 10px;
                    padding: 16px;
                    border-radius: 8px;
                    background: #181818;
                    .flex{
                        margin-bottom: 16px;
                        font-size: 13px;
                        color: rgba(255, 255, 255, 0.6);
                        &:last-child{
                            margin-bottom: 0;
                        }
                        div{
                            flex: 0 0 40%;
                        }
                        p{
                            flex: 0 0 60%;
                            color: #fff;
                            text-align: right;
                        }
                    }
                    .btn{
                        width: 96px;
                        height: 36px;
                        background: url('../../public/images/bilnd/btnbg8.png') center no-repeat;
                        background-size: 100% 100%;
                        font-size: 14px;
                        color: #FFFFFF;
                    }
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .blind {
        padding: 56px 16px;

        .h5con {
            display: block;

            .blind-count {

                .count-info {
                    flex: 0 0 50%;

                    &:last-child {
                        margin-top: 20px;

                        div {
                            color: #C9FA5B;
                        }
                    }
                }
            }
        }
    }
}</style>